import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './landingpage';
import PrivacyPolicy from './PrivacyPolicy';
import FeedbackForm from './FeedbackForm';
import DeleteData from './DeleteData';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/feedback" element={<FeedbackForm />} />
        <Route path="/delete" element={<DeleteData />} />

      </Routes>
    </Router>
  );
};

export default App;