import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow-md">
        <div className="container mx-auto px-6 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold text-blue-600">Nest CRM</Link>
            <Link to="/" className="text-gray-600 hover:text-blue-600">Back to Home</Link>
          </div>
        </div>
      </header>
      <main className="container mx-auto px-6 py-8">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">1. Information We Collect</h2>
          <p className="mb-4">We collect information you provide directly to us, such as when you create an account, use our services, or communicate with us. This information may include your name, email address, phone number, and any other information you choose to provide.</p>
          
          <h2 className="text-xl font-semibold mb-4">2. How We Use Your Information</h2>
          <p className="mb-4">Privacy Policy for Nest CRM

Thank you for choosing to do business with Nest ("Nest" or "Company" or "we" or "us") and visiting https://nestcrm.com (the "Website" or "Site"). We take your information and data privacy seriously. This Website collects certain data from users ("Personal Data") that helps us deliver services, improve website functionality, and may be used to identify or contact a specific person. Our Privacy Policy summarizes what personal information we may collect, how we may use that personal information, and other key topics regarding your privacy and data protection. If you have any questions or concerns regarding our Privacy Policy or data protection practices, please contact us at support@nestcrm.com.

Please review this Privacy Policy carefully. By using and submitting data on our Site, you are consenting to the collection and processing of your data as described in this Privacy Policy. By using the services provided on the Site, you accept the terms of this Privacy Policy and our Terms of Service and consent to the collection, use, disclosure, and retention of your information as described in this Privacy Policy. If you have not done so already, please review our Terms & Conditions..</p>
          
          <h2 className="text-xl font-semibold mb-4">3. Types of Data Collected</h2>
          <p className="mb-4">Nest collects personal information provided by you, information gathered automatically from your use of the Website, information generated from cookies and other technologies, and information related to advertising or analytics. Specific examples of the types and categories of data collected through your use of the Website include: first name; last name; phone number; company name; address; email address; cookies; usage data; product purchase information; usernames and passwords. Nest may also collect any comments, questions, requests, and orders you may make through any forms or contact mechanisms on the Website.

For operation and maintenance purposes, Nest and any third-party services may collect information that records interactions with this Website, such as system logs, IP addresses, MAC addresses, or other Personal Data for this purpose. Nest may also collect technical information regarding your use of the Website, such as browser or device type, operating system, and other technical identifiers. Nest collects this information automatically using cookies and other similar technologies.</p>
          
          <h2 className="text-xl font-semibold mb-4">4. Data Security</h2>
          <p className="mb-4">We take reasonable measures to help protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.</p>
          
          <h2 className="text-xl font-semibold mb-4">5. Cookies and Similar Technologies</h2>
          <p className="mb-4">Cookies are small computer files transferred to your device when visiting a website that contain data about your preferences, browser history, or activities on the Site. Cookies help ensure your experience on our Site is a good one by saving preferences, login information, and providing personalized features. Nest uses cookies that are essential for basic functionality. You can always opt to prevent these cookies from interacting with your device through your browser settings. Other cookies help our Site remember certain interactions and make returning to our Website more convenient. We may also use technologies like Google Analytics to gather information on ad campaigns, browsing activity, and general use of the services of our Site..</p>
          
          <h2 className="text-xl font-semibold mb-4">6. Changes to This Policy</h2>
          <p>Any changes to this Privacy Policy will be posted on the Site. Nest reserves the right to make changes at any time. These modifications will apply only to data collected after the posting of updates.</p>
        </div>
      </main>
      <footer className="bg-gray-800 text-white py-4 mt-8">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 Nest CRM. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;