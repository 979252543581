import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ChevronDown, BarChart, Smartphone, Users, Calendar } from 'lucide-react';
import { Link } from 'react-router-dom';
const App = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="font-sans text-gray-900 bg-gray-100">
      <Header scrollY={scrollY} />
      <Hero />
      <Features />
      <Testimonials />
      <CallToAction />
      <Footer />
    </div>
  );
};

const Header = ({ scrollY }) => (
  <header className={`fixed w-full z-50 transition-all duration-300 ${scrollY > 50 ? 'bg-white shadow-md' : 'bg-transparent'}`}>
    <div className="container mx-auto px-6 py-3 flex justify-between items-center">
      <div className="text-3xl font-bold text-blue-600">Nest CRM</div>
      <nav className="hidden md:flex space-x-6">
        <a href="#features" className="text-gray-700 hover:text-blue-600 transition-colors">Features</a>
        <Link to="/delete" className="text-gray-700 hover:text-blue-600 transition-colors">Delete Data</Link>
        <Link to="/privacy-policy" className="text-gray-700 hover:text-blue-600 transition-colors">Privacy Policy</Link>
        <Link to="/feedback" className="text-gray-700 hover:text-blue-600 transition-colors">Feedback</Link>
      </nav>
      <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors">
        Get Started
      </button>
    </div>
  </header>
);

const Hero = () => (
  <section className="relative h-screen flex items-center justify-center overflow-hidden bg-gradient-to-br from-blue-600 to-purple-600">
    <div className="absolute inset-0">
      {[...Array(20)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute rounded-full bg-white opacity-10"
          style={{
            width: Math.random() * 100 + 50,
            height: Math.random() * 100 + 50,
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
          animate={{
            x: Math.random() * 200 - 100,
            y: Math.random() * 200 - 100,
            scale: [1, 1.1, 1],
            rotate: [0, 180, 360],
          }}
          transition={{
            duration: Math.random() * 10 + 10,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      ))}
    </div>
    <div className="text-center z-10 px-6">
      <motion.h1
        className="text-5xl md:text-6xl font-bold text-white mb-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Revolutionize Your Real Estate Business
      </motion.h1>
      <motion.p
        className="text-xl md:text-2xl text-white mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        Nest CRM: The ultimate tool for modern real estate professionals
      </motion.p>
      <motion.button
        className="bg-white text-blue-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-100 transition-colors"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        Get Started
      </motion.button>
    </div>
    <motion.div
      className="absolute bottom-10 left-1/2 transform -translate-x-1/2"
      animate={{ y: [0, 10, 0] }}
      transition={{ duration: 1.5, repeat: Infinity }}
    >
      <ChevronDown className="text-white w-10 h-10" />
    </motion.div>
  </section>
);

const Features = () => {
  const features = [
    { icon: BarChart, title: 'Advanced Analytics', description: 'Gain valuable insights with our powerful reporting tools.' },
    { icon: Smartphone, title: 'Mobile Friendly', description: 'Access your data anytime, anywhere with our responsive design.' },
    { icon: Users, title: 'Client Management', description: 'Efficiently manage your client relationships in one place.' },
    { icon: Calendar, title: 'Scheduling', description: 'Streamline your appointments and never miss a showing.' },
  ];

  return (
    <section id="features" className="py-20 bg-white">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-12">Why Choose Nest CRM?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="bg-gray-100 p-6 rounded-lg shadow-md"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <feature.icon className="w-12 h-12 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Testimonials = () => {
  const testimonials = [
    { name: 'John Doe', role: 'Real Estate Agent', content: 'Nest CRM has completely transformed how I manage my clients and listings. It\'s an indispensable tool for any serious real estate professional.' },
    { name: 'Jane Smith', role: 'Broker', content: 'The analytics provided by Nest CRM have given us invaluable insights into our market. It\'s helped us make data-driven decisions and grow our business.' },
    { name: 'Mike Johnson', role: 'Property Manager', content: 'The scheduling features in Nest CRM have saved me countless hours. It\'s streamlined our entire showing process.' },
  ];

  return (
    <section id="testimonials" className="py-20 bg-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-12">What Our Users Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <p className="text-gray-600 mb-4">"{testimonial.content}"</p>
              <div className="font-semibold">{testimonial.name}</div>
              <div className="text-sm text-gray-500">{testimonial.role}</div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const CallToAction = () => (
  <section className="py-20 bg-blue-600 text-white">
    <div className="container mx-auto px-6 text-center">
      <h2 className="text-4xl font-bold mb-4">Ready to Transform Your Real Estate Business?</h2>
      <p className="text-xl mb-8">Join thousands of satisfied professionals using Nest CRM</p>
      <button className="bg-white text-blue-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-100 transition-colors">
        Start Your Free Trial
      </button>
    </div>
  </section>
);

const Footer = () => (
  <footer className="bg-gray-800 text-white py-10">
    <div className="container mx-auto px-6">
      <div className="flex flex-wrap justify-between">
        <div className="w-full md:w-1/4 mb-6 md:mb-0">
          <h3 className="text-2xl font-bold mb-4">Nest CRM</h3>
          <p className="text-gray-400">Empowering real estate professionals with cutting-edge technology.</p>
        </div>
        <div className="w-full md:w-1/4 mb-6 md:mb-0">
          <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
          <ul>
            <li><a href="#" className="hover:text-blue-400 transition-colors">Home</a></li>
            <li><a href="#features" className="hover:text-blue-400 transition-colors">Features</a></li>
            <li><a href="#testimonials" className="hover:text-blue-400 transition-colors">Testimonials</a></li>
          </ul>
        </div>
        <div className="w-full md:w-1/4 mb-6 md:mb-0">
          <h4 className="text-lg font-semibold mb-4">Legal</h4>
          <ul>
            <li><a href="#" className="hover:text-blue-400 transition-colors">Privacy Policy</a></li>
            <li><a href="#" className="hover:text-blue-400 transition-colors">Terms of Service</a></li>
          </ul>
        </div>
        <div className="w-full md:w-1/4">
          <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
          <p className="text-gray-400">support@nestcrm.com</p>
          <p className="text-gray-400">1-800-NEST-CRM</p>
        </div>
      </div>
      <div className="border-t border-gray-700 mt-8 pt-8 text-center text-gray-400">
        <p>&copy; 2024 Nest CRM. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

export default App;